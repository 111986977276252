import { useCallback, useContext, useEffect } from 'react'

import { HubSpotSettings } from '../api'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { useExternalScripts } from '../hooks/useExternalScripts'
import { getClientPrimaryEmail } from './AccountAccess/accountEmailStatuses'
import { useAccountReadContext } from './AccountContextContext'
import { useApiClient } from './ApiClient'
import { AuthSessionContext } from './AuthContext'
import { ClientApiClient } from './clientApi'
import { useLocallyPersistedState } from './useStorage'

export const setHubSpotSettings = (settings: HubSpotSettings): void => {
  window.hsConversationsSettings = {
    ...settings,
    loadImmediately: false,
  }
}

export const getHubSpotWidgetStatus = (): { loaded: boolean } | undefined => {
  return window.HubSpotConversations?.widget?.status?.()
}

export const loadHubSpotWidget = (): void => {
  window.HubSpotConversations?.widget?.load?.()
}

export const resetHubSpotWidget = (): void => {
  window.HubSpotConversations?.clear?.({ resetWidget: true })
}

export const useHubSpot = (): void => {
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()
  const [auth] = useContext(AuthSessionContext)
  const apiClient = useApiClient(ClientApiClient)

  const [hubSpotIdentificationToken, setHubSpotIdentificationToken] = useLocallyPersistedState<
    string | undefined
  >('hubSpotIdentificationToken', undefined)

  useExternalScripts('//js.hs-scripts.com/9294264.js', 'hs-script-loader')

  const initializeHubSpot = useCallback(async () => {
    if (!account) {
      resetHubSpotWidget()
      return
    }

    const email = getClientPrimaryEmail(account)

    if (!email) {
      return
    }

    const identificationToken = hubSpotIdentificationToken

    if (!identificationToken) {
      try {
        const identificationToken = (await apiClient.getHBToken())?.token

        setHubSpotIdentificationToken(identificationToken)
      } catch (error: unknown) {
        console.error('Error fetching identification token:', error)
        return
      }
    }

    setHubSpotSettings({
      identificationEmail: email,
      identificationToken: identificationToken,
    })

    if (!window.HubSpotConversations) {
      window.hsConversationsOnReady = [
        () => {
          loadHubSpotWidget()
        },
      ]
    } else {
      loadHubSpotWidget()
    }
  }, [account, apiClient, hubSpotIdentificationToken, setHubSpotIdentificationToken])

  useEffect(() => {
    if (!auth?.email) {
      resetHubSpotWidget()
    } else {
      resetHubSpotWidget()
      initializeHubSpot()
    }
  }, [auth?.email, initializeHubSpot, locale])
}
